import { ActionsConstants } from './ClientServiceRoleActions';

export const actionRoleMap = {
  [ActionsConstants.DELETE_PREALERTS]: ['SERVCLI','OPMIA'],
  [ActionsConstants.CHANGE_PACKAGE_ACCOUNT]: [],
  [ActionsConstants.CHANGE_CLIENT_DIRECTION]: ['CLIENT'],
  [ActionsConstants.ADD_DISCOUNT]: ['SERVCLI','OPCR'],
  [ActionsConstants.REMOVE_DISCOUNT]: [],
  [ActionsConstants.EDIT_CREDIT_CARD]: ['SERVCLI'],
  [ActionsConstants.REMOVE_CREDIT_CARD]: ['SERVCLI'],
  [ActionsConstants.MODIFY_CLIENT_AUTOMATIC_CHARGE]: ['SERVCLI'],
  [ActionsConstants.CONNECT_AS_CLIENT]: ['OPMIA','OPCR','SERVCLI'],
  [ActionsConstants.EMIT_NC_ND]: [],
  [ActionsConstants.EMIT_FE]: ['OPCR','SERVCLI','CONTAB'],
  [ActionsConstants.DELETE_PACKAGE]: [],
  [ActionsConstants.DELETE_ACCOUNT]: [],
  [ActionsConstants.MODIFY_INVOICE]: [],
  [ActionsConstants.EXONERATE_CHARGE]: [],
  [ActionsConstants.ADD_PARCIAL_PAYMENT]: [],
  [ActionsConstants.CHARGE_DELMAN]: [],
  [ActionsConstants.CHECK_ACCOUNT_STATE]: ['OPMIA','CONTAB'],
  [ActionsConstants.ACCOUNT_OPTIONS]: ['OPCR','OPMIA','CONTAB','SERVCLI'],
  [ActionsConstants.VIEW_SHIPMENTS]: ['OPCR','OPMIA','CONTAB','SERVCLI'],
  [ActionsConstants.CREATE_SHIPMENTS]: ['OPMIA','CONTAB'],
  [ActionsConstants.ATTACH_PACKAGES]: ['OPMIA','CONTAB'],
  [ActionsConstants.EDIT_ITEMS]: ['OPMIA','CONTAB','SERVCLI'],
  [ActionsConstants.SCHEDULE_PACKAGES]: ['OPMIA','CONTAB','SERVCLI'],
  [ActionsConstants.EDIT_BILL]: ['OPCR2'],
  [ActionsConstants.PAYMENT_METHODS]: ['OPCR2'],
  [ActionsConstants.SHOW_DELIVERY_OPTIONS]: ['OPMIA','CONTAB','OPCR'],
  [ActionsConstants.ADD_RATE]: [],
  [ActionsConstants.EDIT_RATE]: [],
};