import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

import { UsuariosService } from '../../../../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../../../services/auth.service';
import { Sort } from '@angular/material/sort';
import { EntregasService } from '../../../../../services/entregas.service';

interface DeliveryToken {
  id: number;
  token: string;
  deliveryId: number;
  userId: number;
}

@Component({
  selector: 'app-delivery-token-table',
  templateUrl: './delivery-token-table.component.html',
  styleUrls: ['./delivery-token-table.component.scss']
})
export class DeliveryTokenTableComponent implements OnInit {
  displayedColumns: string[] = ['account','deliveryId', 'driver','token','created_at','valid','max_date'];
  dataSource = new MatTableDataSource<DeliveryToken>();

  constructor(
    private entregasService: EntregasService,
  ) { }

  ngOnInit(): void {
    this.getDeliveyTokens();
  }

  getDeliveyTokens() {
    this.entregasService.getDeliveryTokens().subscribe((data: any) => {
      this.dataSource.data = data;
    });
  }

}
