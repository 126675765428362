<div style="overflow: auto; height: 100%;" class="general">
  <h3 mat-dialog-title class="modal-title text-center">{{data.title | translate}}</h3>

  <div class="col-12 text-center">
    <div class="mt-4">
      <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED' || metodoEntrega == 'AM'">
        <mat-label>{{'Fecha de Entrega' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="disabledDates"
          placeholder="{{'Fecha de Entrega' |translate}}" [(ngModel)]="fechaEntrega" (dateInput)="validateDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'Método de pago' | translate}}</mat-label>
        <mat-select [(ngModel)]="metodoPago">
          <mat-option *ngFor="let metodo of metodosPago" [value]="metodo.id">
            {{metodo.nombre | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="user.rol == 'ADMIN' && metodoPago == 7">
        <mat-form-field appearance="outline">
          <mat-label>Detalle</mat-label>
          <input matInput [(ngModel)]="detail">
        </mat-form-field>
      </ng-container>

      <form style="width: 100%" *ngIf="metodoPago == 9">
        <p style="text-align: left;">Datos Personales</p>
        <hr>
        <mat-form-field style="display: block !important;" appearance="outline">
          <mat-label>Número de Cédula</mat-label>
          <input matInput [(ngModel)]="dvForm.amount" name="amount">
        </mat-form-field>

        <button mat-flat-button color="primary" class="button1" (click)="buscarPago()" type="button">
          Buscar pago
        </button>

        <div class="alert alert-info mt-4" *ngIf="dvResult">
          <p>
            {{dvResult.descripcion}}
          </p>
          <table mat-table [dataSource]="dvResult.pagos">
            <ng-container matColumnDef="descripcion">
              <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Descripción</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Monto</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto}} </td>
            </ng-container>

            <ng-container matColumnDef="fechaRecibido">
              <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Fecha </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.fechaRecibido | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="codReferencia">
              <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Referencia </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.codReferencia}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Acciones</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element">
                <button mat-flat-button
                  style="background-color: green !important; color: white !important; margin-right: 20px;"
                  (click)="attachPayment(element)">
                  <mat-icon style="color: white !important;">description</mat-icon>Vincular
                </button>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="dvColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dvColumns;"></tr>
          </table>
        </div>

      </form>


      <!-- Comprobante 1 -->
      <div class="col-12 pb-2" *ngIf="(metodoPago == 2 || metodoPago == 3)">
        <div>
          <div style="margin-bottom: 20px;">
            <div style="display: flex; flex-direction: row; align-items: flex-start;">
              <span class="pill">1</span>
              <span style="margin-left: 10px;" >Realizá tu pago de <strong>{{data.monto_crc | currency:'CRC':'symbol-narrow':'1.2-2'}}</strong> 
                via SINPE Móvil al <strong>6037-6729.</strong> El monto debe ser exacto.</span>
            </div>
            <div style="display: flex; flex-direction: row; align-items: flex-start;">
              <span class="pill">2</span>
              <span style="margin-left: 10px;">Una vez que hayás realizado el pago ingresá tu cédula abajo.</span>
            </div>
            <div style="display: flex; flex-direction: row; align-items: flex-start;">
              <span class="pill">3</span>
              <span style="margin-left: 10px;">Da click en "Validar Pago" y listo. Automáticamente buscaremos tu pago en el banco y lo asociaremos a tu orden.</span>
            </div>
          </div>
          <div class="mb-2">
            <!-- <label class="label">Tipo de cédula</label><br> -->
            <ng-select [items]="govTypes" bindLabel="name" bindValue="id" placeholder="Seleccione el tipo de cédula" [searchable]="false" 
            [multiple]="false" [(ngModel)]="govIdType" ></ng-select>
          </div>
          <div style="display: flex; align-items: center; width: 100%; gap: 10px;">
            <mat-form-field appearance="outline" style="flex: 1;">
              <mat-label>{{'Número de cédula' | translate}}</mat-label>
              <input matInput placeholder="{{'Número de cédula' | translate}}" [(ngModel)]="govId">
              <mat-hint>{{'Ingrese la cédula sin guiones' | translate}}</mat-hint>
            </mat-form-field>
            <button button mat-flat-button color="primary" class="button1" style="flex-shrink: 0;" (click)="verifyPayments()">Validar Pago</button>
          </div>
          <!--<div *ngIf="dvResult && dvResult.codRespuesta == dvPaymmentResponses.multiplePayments && dvResult.pagos.length > 1" class="mb-5">
            <table mat-table [dataSource]="dvResult.pagos">

              <ng-container matColumnDef="select">
                <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef> {{'Seleccionar' | translate}} </th>
                <td mat-cell class="pr-pl-20 text-center" *matCellDef="let row">
                  <mat-checkbox color="primary" 
                  (change)="changeCheck($event,row)"
                  [checked]="isChecked(row)" >
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="descripcion">
                <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Descripción</th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
              </ng-container>
  
              <ng-container matColumnDef="monto">
                <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Monto</th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto | currency:'CRC':'symbol-narrow':'1.2-2'}} </td>
              </ng-container>
  
              <ng-container matColumnDef="fechaRecibido">
                <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.fechaRecibido | dateFormat}} </td>
              </ng-container>
  
              <ng-container matColumnDef="codReferencia">
                <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Referencia </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.codReferencia}} </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="dvColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: dvColumns;"></tr>
             
            </table>
            <div class="mt-3">Total para aplicar: {{this.selectedPaymentTotal | currency:'CRC':'symbol-narrow':'1.2-2'}}</div>
          </div> -->
          
          <div>
            <div *ngIf="dvResult && dvResult.pagos.length > 0 && selectedPackages.length == 1" style="color: white; background-color: green; padding: 10px 20px; border-radius: 50px; display: inline-block; font-size: 14px; font-weight: bold; margin-bottom: 6px;">Pago encontrado</div>
            <!-- <div *ngIf="dvResult && dvResult.pagos.length == 0 " style="color: white; background-color: red; padding: 10px 20px; border-radius: 50px; display: inline-block; font-size: 14px; font-weight: bold; margin-bottom: 6px;"> Pago no encontrado
            </div> -->
            <div *ngIf="dvResult && selectedPackages.length == 0" style="color: red; align-items: start;">
              <span style="display: block;">No se han encontrado pagos asociados a este número de cédula.</span>
              <span style="display: block;">Asegurate de haber realizado el pago con el monto exacto.</span>
              <span style="display: block;">El número de cédula ingresado debe ser el del titular de la cuenta.</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="metodoPago == 8">
        <label class="label"><strong>{{'Adjuntar Comprobante de Pago' | translate}}</strong></label><br>

        <mat-form-field appearance="outline">
          <mat-label>{{'Número de Comprobante' | translate}}</mat-label>
          <input matInput placeholder="{{'Número de Comprobante' | translate}}" [(ngModel)]="nrocomprobante1">
        </mat-form-field>

        <br>
        <div fileDragDrop (filesChangeEmiter)="processFile1($event)">
          <img *ngIf="!viewFile1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            #imageF1 width="150" height="150" style="border: 1px solid black;">

          <label style="background: none; border: 1px solid;" *ngIf="viewFile1 && editF1"
            class="button-archivo mt-2">{{fileLoad1.name}}</label>
          <br>
          <button class="button-archivo mt-2" (click)="imageInput1.click(); $event.preventDefault()" mat-flat-button
            color="primary"><i class="material-icons left">file_upload</i>{{'Seleccionar' | translate}}</button>
          <input style="width: 100%; display: none;" type="file" class="form-control-file"
            accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
            #imageInput1 (change)="processFile1($event.target.files)">
        </div>
        <div class="mx-4">
          <p class="mx-4 mt-1 mb-1 ">
            {{'Para adjuntar su comprobante puede usar los siguiente tipos de archivos PNG, JPG, WORD, PDF y el
            archivo debe ser menor a 20MB.' | translate}}
          </p>
        </div>
      </div>

      <!-- Comprobante 2 Encomienda-->
      <div class="col-12 pb-2"
        *ngIf="(metodoPago == 2 || metodoPago == 3) && nrocomprobante1.length > 0 && totalEncomienda>0">
        <div>
          <label class="label"><strong>{{'Adjuntar Comprobante de Pago Encomienda' | translate}}</strong></label><br>

          <mat-form-field appearance="outline">
            <mat-label>{{'Número de Comprobante' | translate}}</mat-label>
            <input matInput placeholder="{{'Número de Comprobante' | translate}}" [(ngModel)]="nrocomprobante2">
          </mat-form-field>

          <br>
          <div fileDragDrop (filesChangeEmiter)="processFile2($event)">
            <img *ngIf="!viewFile2" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              #imageF2 width="150" height="150" style="border: 1px solid black;">

            <label style="background: none; border: 1px solid;" *ngIf="viewFile2 && editF2"
              class="button-archivo mt-2">{{fileLoad2.name}}</label>
            <br>
            <button class="button-archivo mt-2" (click)="imageInput2.click(); $event.preventDefault()" mat-flat-button
              color="primary"><i class="material-icons left">file_upload</i>{{'Seleccionar' | translate}}</button>
            <input style="width: 100%; display: none;" type="file" class="form-control-file"
              accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
              #imageInput2 (change)="processFile2($event.target.files)">
          </div>
          <div class="mx-4">
            <p class="mx-4 mt-1 mb-1 ">
              {{'Para adjuntar su comprobante puede usar los siguiente tipos de archivos PNG, JPG, WORD, PDF y el
              archivo debe ser menor a 20MB.' | translate}}
            </p>
          </div>
        </div>
      </div>

      <mat-accordion multi *ngIf="user.rol == 'ADMIN'">
        <mat-expansion-panel *ngIf="metodoEntrega == 'ED'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'Datos de Entrega' | translate}}
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon>local_shipping</mat-icon>
              {{'Verifique o modifique su dirección de entrega.' | translate}}

            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED'">
            <mat-label>{{'Dirección de entrega' | translate}}</mat-label>
            <input matInput id="direccion_entrega" name="direccion_entrega" #direccion_entrega
              placeholder="Dirección de entrega" [(ngModel)]="direccionEntrega" (change)="onAddressChange()"
              [readonly]="readonly">
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED'">
            <mat-label>{{'Provincia' | translate}}</mat-label>
            <mat-select #provinciaInput matInput placeholder="Seleccione Provincia" required [(ngModel)]="provincia"
              (valueChange)="getCantones($event); onAddressChange(); canton=null; distrito=null" [disabled]="readonly">
              <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                {{provincia.provincia}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED'">
            <mat-label>{{'Cantón' | translate}}</mat-label>
            <mat-select matInput placeholder="Seleccione Cantón" required [(ngModel)]="canton"
              (valueChange)="getDistritos(provincia,$event); onAddressChange(); distrito = null" [disabled]="readonly">
              <mat-option *ngFor="let canton of cantones" [value]="canton.id">
                {{canton.canton}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED'">
            <mat-label>{{'Distrito' | translate}}</mat-label>
            <mat-select matInput placeholder="Seleccione Distrito" required [(ngModel)]="distrito" #dist
              (valueChange)="onAddressChange(dist.value)" [disabled]="readonly">
              <mat-option *ngFor="let distrito of distritos" [value]="distrito.id">
                {{distrito.distrito}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED'">
            <mat-label>{{'Detalles Adicionales' | translate}}</mat-label>
            <input matInput placeholder="Dirección de Entrega" [(ngModel)]="detallesAdicionales" [readonly]="readonly">
          </mat-form-field>

          <div *ngIf="formEntregaChanged">
            <span *ngIf="tipoZonaEntrega == 'v'" class="green">{{'Entrega Gratuita Disponible' | translate}}</span>
            <span *ngIf="tipoZonaEntrega == 'a' && autoriza_entrega  == false" class="yellow">
              {{'Comuníquese con atención al cliente para entregas a esta zona' | translate}}
            </span>
            <span *ngIf="tipoZonaEntrega == 'a' && autoriza_entrega  == true" class="yellow">
              {{'Entrega Gratuita Disponible' | translate}}
            </span>
            <span *ngIf="tipoZonaEntrega == 'r' && this.totalEncomienda > 0" class="red">
              {{'Se aplicará una tarifa adicional por encomienda de' | translate}} {{totalEncomienda |
              currency:'USD':'symbol-narrow':'1.2-2'}} / {{ totalEncomiendaCRC |
              currency:'CRC':'symbol-narrow':'1.2-2'}}
            </span>
          </div>

          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapClick)="markerClick($event)"
            *ngIf="metodoEntrega == 'ED'">
            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
              (dragEnd)="markerDragEnd($event); onAddressChange()"></agm-marker>
          </agm-map>

          <mat-form-field appearance="outline" *ngIf="metodoEntrega == 'ED'">
            <mat-label>{{'Instrucciones de entrega' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="entregaObservacion" rows="5" [disabled]="readonly"></textarea>
          </mat-form-field>

        </mat-expansion-panel>
      </mat-accordion>

      <span *ngIf="tipoZonaEntrega == 'r' && this.totalEncomienda < 0" class="red">
        {{'Una tarifa adicional por encomienda aplica para este envío.' | translate}} <br> {{'Comuníquese con Atención
        al cliente para cotizar su envío.' | translate}}
      </span>

      <div style="font-size: 24px;" class="mt-4">
        {{'Total a Pagar' | translate}}:
        <strong>
          {{data.monto_usd | currency:'USD':'symbol-narrow':'1.2-2'}}
          /
          {{data.monto_crc | currency:'CRC':'symbol-narrow':'1.2-2'}}
        </strong>
      </div>

      <div *ngIf="displayLegend" style="font-weight: bold; font-size: 16px;">
        {{'Por favor enviar su comprobante de pago al WhatsApp +506 6037-6729' | translate}}
      </div>

    </div>
  </div>

  <div mat-dialog-actions style="display: flex; justify-content: space-between;">

    <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">{{'Cancelar' | translate}}</button>
    <button mat-flat-button color="primary" class="button1" (click)="programarEntrega()"
      [disabled]="!fechaEntrega || (metodoPago == 2 && (selectedPackages.length == 0 || validatePayment))">{{'Aceptar' |
      translate}}</button>
  </div>

</div>
