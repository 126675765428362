import { GuiaComponent } from './pages/almacen/embarques/guia/guia.component';
import { ContratoComponent } from './pages/administracion/contrato/contrato.component';
import { TipoClienteComponent } from './pages/administracion/tipo-cliente/tipo-cliente.component';
import { FacturasSingenerarComponent } from './pages/contabilidad/facturas-singenerar/facturas-singenerar.component';
import { AlianzaComponent } from './pages/administracion/alianzas/alianza/alianza.component';
import { IndexAlianzaComponent } from './pages/administracion/alianzas/index/index.component';
import { AlianzasComponent } from './pages/administracion/alianzas/alianzas.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent as DashboardTemplate } from './dashboard/dashboard.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { IndexComponent as CuentasIndex } from './pages/cuentas/index/index.component';
import { PaqueteComponent } from './pages/paquetes/paquete/paquete.component';
import { CuentasComponent } from './pages/cuentas/cuentas.component';
import { CreateComponent as CuentasCreate } from './pages/cuentas/create/create.component';
import { AlmacenComponent } from './pages/almacen/almacen.component';
import { CapturarPaquetesComponent } from './pages/almacen/capturar-paquetes/capturar-paquetes.component';
import { AdministracionComponent } from './pages/administracion/administracion.component';
import { IndexComponent as AdministracionIndex } from './pages/administracion/index/index.component';
import { TarifasComponent } from './pages/administracion/tarifas/tarifas/tarifas.component';
import { AccionesComponent } from './pages/administracion/acciones/acciones.component';
import { DescuentosComponent } from './pages/administracion/descuentos/descuentos.component';
import { EmailComponent } from './pages/administracion/email/email.component';
import { ContabilidadComponent } from './pages/contabilidad/contabilidad.component';
import { PagosComponent } from './pages/contabilidad/pagos/pagos.component';
import { FacturasComponent as FacturasContComponent } from './pages/contabilidad/facturas/facturas.component';
import { ComprasCorporativasComponent } from './pages/compras-corporativas/compras-corporativas.component';
import { OtrosComponent } from './pages/otros/otros.component';
import { IndexComponent as OtrosIndex } from './pages/otros/index/index.component';;

import { RouteComponent } from './pages/otros/route/route.component';
import { ReportesComponent } from './pages/reportes/reportes.component';

import { TarifasDeProductosComponent } from './pages/administracion/tarifas-de-productos/tarifas-de-productos.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { Role } from '../../models/role';
import { NotasDeCreditoComponent } from './pages/contabilidad/notas-de-credito/notas-de-credito.component';
import { HistoryComponent } from './pages/history/history.component';

import { IndexComponent as ComprasCorporativasIndex } from './pages/compras-corporativas/index/index.component';
import { AgregarCompraComponent } from './pages/compras-corporativas/agregar-compra/agregar-compra.component';

import { AddCurrencyRateComponent } from './pages/otros/add-currency-rate/add-currency-rate.component';

import { AddRouteComponent } from './pages/otros/add-route/add-route.component';
import { EditRouteComponent } from './pages/otros/edit-route/edit-route.component';
import { AccionComponent } from './pages/administracion/acciones/accion/accion.component';
import { IndexComponent as AccionesIndex } from './pages/administracion/acciones/index/index.component';
import { IndexComponent as DescuentosIndex } from './pages/administracion/descuentos/index/index.component';
import { DescuentoComponent } from './pages/administracion/descuentos/descuento/descuento.component';
import { IndexComponent as TarifaProductoIndex } from './pages/administracion/tarifas-de-productos/index/index.component';
import { TarifaProductoComponent } from './pages/administracion/tarifas-de-productos/tarifa-de-producto/tarifa-de-producto.component';

import { NuevoComponent as NuevoEmail } from './pages/administracion/email/nuevo/nuevo.component';
import { PerfilComponent } from '../common/pages/perfil/perfil.component';
import { CalculadoraComponent } from '../common/pages/calculadora/calculadora.component';
import { TarifaComponent } from './pages/administracion/tarifas/tarifa/tarifa.component';
import { IndexEmbarquesComponent } from './pages/almacen/embarques/index/index.component';
import { EmbarquesComponent } from './pages/almacen/embarques/embarques/embarques.component';
import { EmbarqueComponent } from './pages/almacen/embarques/embarque/embarque.component';
import { IndexEntregasComponent } from './pages/almacen/entregas/index/index.component';
import { EntregasComponent } from './pages/almacen/entregas/entregas/entregas.component';
import { EntregaComponent } from './pages/almacen/entregas/entrega/entrega.component';
import { ProgramarEntregasComponent } from './pages/almacen/entregas/programar-entregas/programar-entregas.component';
import { MainEmbarquesComponent } from './pages/almacen/embarques/main-embarques.component';
import { MainEntregasComponent } from './pages/almacen/entregas/main-entregas.component';
import { CapturarEmbarquesComponent } from './pages/almacen/capturar-embarques/capturar-embarques.component';
import { MainPaquetesComponent } from './pages/paquetes/main-paquetes.component';
import { BuscarComponent } from './pages/buscar/buscar.component';
import { ResumenPaquetesComponent } from './pages/paquetes/resumen-paquetes/resumen-paquetes.component';
import { PaquetesComponent } from './pages/paquetes/paquetes/paquetes.component';
import { ConsolidacionComponent } from './pages/almacen/consolidacion/consolidacion.component';
import { MainTarifasComponent } from './pages/administracion/tarifas/main-tarifas.component';
import { CapturarEmbarquesAdminComponent } from './pages/almacen/capturar-embarques-admin/capturar-embarques-admin.component';
import { TransportistasComponent } from './pages/almacen/entregas/transportistas/transportistas/transportistas.component';
import { TransportistaComponent } from './pages/almacen/entregas/transportistas/transportista/transportista.component';
import { CapturarPaquetesZonaComponent } from './pages/almacen/capturar-paquetes-zona/capturar-paquetes-zona.component';
import { AprobarPagosComponent } from './pages/contabilidad/aprobar-pagos/aprobar-pagos.component';
import { TarjetasComponent } from './pages/seguridad/tarjetas/tarjetas.component';
import { ReportViewerComponent } from './pages/reportes/report-viewer/report-viewer.component';
import { VendedoresComponent } from './pages/vendedores/vendedores/vendedores.component';
import { VendedorComponent } from './pages/vendedores/vendedor/vendedor.component';
import { MainSucursalesComponent } from './pages/almacen/sucursales/main-sucursales.component';
import { IndexSucursalesComponent } from './pages/almacen/sucursales/index/index.component';
import { ClientesRutaComponent } from './pages/cuentas/clientes-ruta/clientes-ruta.component';
import { IntegracionesComponent } from './pages/administracion/integraciones/integraciones.component';
import { NotificacionesPushComponent } from './pages/administracion/notificaciones-push/notificaciones-push.component';
import { SuperboxComponent } from './pages/cuentas/superbox/superbox.component';
import { MassEmailsComponent } from './pages/administracion/email/mass-emails/mass-emails.component';
import { DeliveryTokenTableComponent } from './pages/almacen/delivery-token-table/delivery-token-table.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardTemplate,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ADMINISTRADOR]
    },
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'buscar',
        component: BuscarComponent
      },
      {
        path: 'paquetes',
        component: MainPaquetesComponent,
        children: [
          {
            path: '',
            component: ResumenPaquetesComponent
          },
          {
            path: 'buscar',
            component: PaquetesComponent
          },
          {
            path: 'paquete',
            component: PaqueteComponent
          },
        ]
      },
      {
        path: 'calculadora',
        component: CalculadoraComponent
      },
      {
        path: 'cuentas',
        component: CuentasComponent,
        children: [
          {
            path: '',
            component: CuentasIndex
          },
          {
            path: 'crear/:tipo',
            component: CuentasCreate
          },
          {
            path: 'rutas',
            component: ClientesRutaComponent
          },
          {
            path: 'superbox',
            component: SuperboxComponent
          }
        ]
      },
      {
        path: 'almacen',
        component: AlmacenComponent,
        children: [
          {
            path: 'capturarPaquetes',
            component: CapturarPaquetesComponent
          },
          {
            path: 'capturarEmbarques',
            component: CapturarEmbarquesComponent
          },
          {
            path: 'capturarEmbarquesAdmin',
            component: CapturarEmbarquesAdminComponent
          },
          {
            path: 'capturar-zona',
            component: CapturarPaquetesZonaComponent
          },
          {
            path: 'consolidados',
            component: ConsolidacionComponent
          },
          {
            path: 'embarques',
            component: MainEmbarquesComponent,
            children: [
              {
                path: '',
                component: IndexEmbarquesComponent
              },
              {
                path: 'list',
                component: EmbarquesComponent
              },
              {
                path: 'agregar',
                component: EmbarqueComponent,

              },
              {
                path: 'guia',
                component: GuiaComponent,
              },

            ]
          },
          {
            path: 'entregas',
            component: MainEntregasComponent,
            children: [
              {
                path: '',
                component: IndexEntregasComponent
              },
              {
                path: 'list',
                component: EntregasComponent
              },
              {
                path: 'agregar',
                component: EntregaComponent
              },
              {
                path: 'programar',
                component: ProgramarEntregasComponent
              },
              {
                path: 'transportistas',
                component: TransportistasComponent,
              },
              {
                path: 'transportista',
                component: TransportistaComponent
              },
            ]
          },
          {
            path: 'entregas/codigos',
            component: DeliveryTokenTableComponent
          },
          {
            path: 'sucursales',
            component: MainSucursalesComponent,
            children: [
              {
                path: '',
                component: IndexSucursalesComponent
              },
            ]
          },
        ]
      },
      {
        path: 'administracion',
        component: AdministracionComponent,
        children: [
          {
            path: '',
            component: AdministracionIndex
          },
          {
            path: 'tarifas',
            component: MainTarifasComponent,
            children: [
              {
                path: '',
                component: TarifasComponent
              },
              {
                path: 'tarifa',
                component: TarifaComponent
              },
            ]
          },
          {
            path: 'alianzas',
            component: AlianzasComponent,
            children: [
              {
                path: '',
                component: IndexAlianzaComponent
              },
              {
                path: 'alianza',
                component: AlianzaComponent
              },
            ]
          },
          {
            path: 'tarifa-productos',
            component: TarifasDeProductosComponent,
            children: [
              {
                path: '',
                component: TarifaProductoIndex
              },
              {
                path: 'tarifa',
                component: TarifaProductoComponent
              }
            ]
          },
          {
            path: 'acciones',
            component: AccionesComponent,
            children: [
              {
                path: '',
                component: AccionesIndex
              },
              {
                path: 'accion',
                component: AccionComponent
              }
            ]
          },
          {
            path: 'descuentos',
            component: DescuentosComponent,
            children: [
              {
                path: '',
                component: DescuentosIndex
              },
              {
                path: 'agregar',
                component: DescuentoComponent
              }
            ]
          },
          {
            path: 'email',
            component: EmailComponent
          },
          {
            path: 'email/new',
            component: NuevoEmail
          },
          {
            path: 'email/mass',
            component: MassEmailsComponent
          },
          {
            path: 'integraciones',
            component: IntegracionesComponent
          },
          {
            path: 'push',
            component: NotificacionesPushComponent
          },
          {
            path: 'tipo_cliente',
            component: TipoClienteComponent
          },
          {
            path: 'contrato',
            component: ContratoComponent
          }
        ]
      },
      {
        path: 'contabilidad',
        component: ContabilidadComponent,
        children: [
          {
            path: 'facturas',
            component: FacturasContComponent
          },
          {
            path: 'pagos',
            component: PagosComponent
          },
          {
            path: 'aprobar-pagos',
            component: AprobarPagosComponent
          },
          {
            path: 'notas-de-credito',
            component: NotasDeCreditoComponent
          },
          {
            path: 'facturas-singenerar',
            component: FacturasSingenerarComponent
          }
        ]
      },
      {
        path: 'compras-corporativas',
        component: ComprasCorporativasComponent,
        children: [
          {
            path: '',
            component: ComprasCorporativasIndex
          },
          {
            path: 'agregar',
            component: AgregarCompraComponent
          }
        ]
      },
      {
        path: 'reportes',
        component: ReportesComponent,
        children: [
          {
            path: '',
            component: ReportViewerComponent
          },
          {
            path: 'viewer',
            component: ReportViewerComponent
          },
        ]
      },
      {
        path: 'tarjetas',
        component: TarjetasComponent
      },
      {
        path: 'otros',
        component: OtrosComponent,
        children: [
          {
            path: '',
            component: OtrosIndex
          },
          {
            path: 'agregar',
            component: AddCurrencyRateComponent
          },
          {
            path: 'rutas',
            component: RouteComponent
          },
          {
            path: 'rutas/agregar',
            component: AddRouteComponent
          },
          {
            path: 'rutas/editar',
            component: EditRouteComponent
          },
        ]
      },
      {
        path: 'history',
        component: HistoryComponent,
      },
      {
        path: 'perfil',
        component: PerfilComponent
      },
      {
        path: 'vendedores',
        component: VendedoresComponent,
      },
      {
        path: 'vendedor',
        component: VendedorComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
