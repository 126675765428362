import { NgModule } from '@angular/core';

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent as DashboardTemplate } from './dashboard/dashboard.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { CKEditorModule } from 'ckeditor4-angular';
import { NgxFileDropModule } from 'ngx-file-drop';

import { ChartsModule } from 'ng2-charts';

import { PaqueteComponent } from './pages/paquetes/paquete/paquete.component';

import { AccionesComponent } from './pages/administracion/acciones/acciones.component';
import { AdministracionComponent } from './pages/administracion/administracion.component';
import { DescuentosComponent } from './pages/administracion/descuentos/descuentos.component';
import { IndexComponent as AdministracionIndex } from './pages/administracion/index/index.component';
import { ComprasCorporativasComponent } from './pages/compras-corporativas/compras-corporativas.component';
import { ContabilidadComponent } from './pages/contabilidad/contabilidad.component';
import { FacturasComponent as FacturasContComponent } from './pages/contabilidad/facturas/facturas.component';
import { PagosComponent } from './pages/contabilidad/pagos/pagos.component';
import { CreateComponent as CuentasCreate } from './pages/cuentas/create/create.component';
import { CuentasComponent } from './pages/cuentas/cuentas.component';
import { IndexComponent as CuentasIndex } from './pages/cuentas/index/index.component';

import { IndexComponent as OtrosIndex } from './pages/otros/index/index.component';
import { OtrosComponent } from './pages/otros/otros.component';
import { RouteComponent } from './pages/otros/route/route.component';
import { ReportesComponent } from './pages/reportes/reportes.component';

import { GlobalModule } from '../../global.module';
import { EmailComponent } from './pages/administracion/email/email.component';

import { TarifasDeProductosComponent } from './pages/administracion/tarifas-de-productos/tarifas-de-productos.component';

import { TarifaProductoComponent } from './pages/administracion/tarifas-de-productos/tarifa-de-producto/tarifa-de-producto.component';

import { CkeditorComponent } from './components/ckeditor/ckeditor.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { AgregarCompraComponent } from './pages/compras-corporativas/agregar-compra/agregar-compra.component';
import { IndexComponent as ComprasCorporativasIndex } from './pages/compras-corporativas/index/index.component';
import { NotasDeCreditoComponent } from './pages/contabilidad/notas-de-credito/notas-de-credito.component';

import { AddCurrencyRateComponent } from './pages/otros/add-currency-rate/add-currency-rate.component';

import { AccionComponent } from './pages/administracion/acciones/accion/accion.component';
import { IndexComponent as AccionesIndex } from './pages/administracion/acciones/index/index.component';
import { DescuentoComponent } from './pages/administracion/descuentos/descuento/descuento.component';
import { IndexComponent as DescuentosIndex } from './pages/administracion/descuentos/index/index.component';
import { IndexComponent as TarifaProductoIndex } from './pages/administracion/tarifas-de-productos/index/index.component';
import { AddRouteComponent } from './pages/otros/add-route/add-route.component';
import { EditRouteComponent } from './pages/otros/edit-route/edit-route.component';

import { BarComponent } from './components/charts/bar/bar.component';
import { BubbleComponent } from './components/charts/bubble/bubble.component';
import { ChartsComponent } from './components/charts/charts.component';
import { DoughnutPieComponent } from './components/charts/doughnut-pie/doughnut-pie.component';
import { LineComponent } from './components/charts/line/line.component';
import { PolarAreaComponent } from './components/charts/polar-area/polar-area.component';
import { RadarComponent } from './components/charts/radar/radar.component';
import { ScatterComponent } from './components/charts/scatter/scatter.component';
import { NuevoComponent } from './pages/administracion/email/nuevo/nuevo.component';
import { MassEmailsComponent } from './pages/administracion/email/mass-emails/mass-emails.component';
import { VistaComponent } from './pages/administracion/email/vista/vista.component';
import { HistoryComponent } from './pages/history/history.component';

import { EmbarqueComponent } from './pages/almacen/embarques/embarque/embarque.component';

import { MaterialModule } from '../../material.module';
import { SharedComponentsModule } from '../common/shared-components.module';
import { NewPackageComponent } from './components/newpackage/newpackage.component';
import { TarifaComponent } from './pages/administracion/tarifas/tarifa/tarifa.component';

import { AgmCoreModule } from '@agm/core';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { WebcamModule } from 'ngx-webcam';
import { UltimaActualizacionPipe } from 'src/app/shared/pipes/ultima-actualizacion.pipe';
import { FilterUsuarioEntregadoPipe } from '../../../app/shared/pipes/filter-usuario-entregado.pipe';
import { VendedorComponent } from '../admin/pages/vendedores/vendedor/vendedor.component';
import { VendedoresComponent } from '../admin/pages/vendedores/vendedores/vendedores.component';
import { CardComponent } from '../user/components/card/card.component';
import { AppPackageComponent } from './components/app-package/app-package.component';
import { CameraModalComponent } from './components/camera-modal/camera-modal.component';
import { CameraComponent } from './components/camera/camera.component';
import { ConsolidationModalComponent } from './components/consolidation-modal/consolidation-modal.component';
import { DialogModalComponent } from './components/dialog-modal/dialog-modal.component';
import { EmailEditorModalComponent } from './components/emaileditor-modal/emaileditor-modal.component';
import { FacturaModalComponent } from './components/factura-modal/factura-modal.component';
import { GuiasComponent } from './components/guias/guias.component';
import { PackageLabelComponent } from './components/package-label/package-label.component';
import { PaqueteItemsComponent } from './components/paquete-items/paquete-items.component';
import { TarifaModalComponent } from './components/tarifa-modal/tarifa-modal.component';
import { WindowComponent } from './components/window/window.component';
import { AlianzaComponent } from './pages/administracion/alianzas/alianza/alianza.component';
import { AlianzasComponent } from './pages/administracion/alianzas/alianzas.component';
import { IndexAlianzaComponent } from './pages/administracion/alianzas/index/index.component';
import { ContratoComponent } from './pages/administracion/contrato/contrato.component';
import { IntegracionesComponent } from './pages/administracion/integraciones/integraciones.component';
import { NotificacionesPushComponent } from './pages/administracion/notificaciones-push/notificaciones-push.component';
import { MainTarifasComponent } from './pages/administracion/tarifas/main-tarifas.component';
import { TarifasComponent } from './pages/administracion/tarifas/tarifas/tarifas.component';
import { TipoClienteComponent } from './pages/administracion/tipo-cliente/tipo-cliente.component';
import { AlmacenComponent } from './pages/almacen/almacen.component';
import { CapturarEmbarquesAdminComponent } from './pages/almacen/capturar-embarques-admin/capturar-embarques-admin.component';
import { CapturarEmbarquesComponent } from './pages/almacen/capturar-embarques/capturar-embarques.component';
import { CapturarPaquetesZonaComponent } from './pages/almacen/capturar-paquetes-zona/capturar-paquetes-zona.component';
import { CapturarPaquetesComponent } from './pages/almacen/capturar-paquetes/capturar-paquetes.component';
import { ConsolidacionComponent } from './pages/almacen/consolidacion/consolidacion.component';
import { EmbarquesComponent } from './pages/almacen/embarques/embarques/embarques.component';
import { GuiaComponent } from './pages/almacen/embarques/guia/guia.component';
import { IndexEmbarquesComponent } from './pages/almacen/embarques/index/index.component';
import { MainEmbarquesComponent } from './pages/almacen/embarques/main-embarques.component';
import { EntregaComponent } from './pages/almacen/entregas/entrega/entrega.component';
import { EntregasComponent } from './pages/almacen/entregas/entregas/entregas.component';
import { IndexEntregasComponent } from './pages/almacen/entregas/index/index.component';
import { MainEntregasComponent } from './pages/almacen/entregas/main-entregas.component';
import { ProgramarEntregasComponent } from './pages/almacen/entregas/programar-entregas/programar-entregas.component';
import { TransportistaComponent } from './pages/almacen/entregas/transportistas/transportista/transportista.component';
import { TransportistasComponent } from './pages/almacen/entregas/transportistas/transportistas/transportistas.component';
import { IndexSucursalesComponent } from './pages/almacen/sucursales/index/index.component';
import { MainSucursalesComponent } from './pages/almacen/sucursales/main-sucursales.component';
import { BuscarComponent } from './pages/buscar/buscar.component';
import { AprobarPagosComponent } from './pages/contabilidad/aprobar-pagos/aprobar-pagos.component';
import { FacturasSingenerarComponent } from './pages/contabilidad/facturas-singenerar/facturas-singenerar.component';
import { ClientesRutaComponent } from './pages/cuentas/clientes-ruta/clientes-ruta.component';
import { SuperboxComponent } from './pages/cuentas/superbox/superbox.component';
import { MainPaquetesComponent } from './pages/paquetes/main-paquetes.component';
import { PaquetesComponent } from './pages/paquetes/paquetes/paquetes.component';
import { ResumenPaquetesComponent } from './pages/paquetes/resumen-paquetes/resumen-paquetes.component';
import { ReportViewerComponent } from './pages/reportes/report-viewer/report-viewer.component';
import { TarjetasComponent } from './pages/seguridad/tarjetas/tarjetas.component';
import { DeliveryTokenTableComponent } from './pages/almacen/delivery-token-table/delivery-token-table.component';

@NgModule({
  declarations: [
    DashboardComponent, DashboardTemplate,
    CuentasComponent, CuentasIndex, CuentasCreate,
    AlmacenComponent, CapturarPaquetesComponent,
    AdministracionComponent, AdministracionIndex,
    AccionesComponent,
    DescuentosComponent,
    ContabilidadComponent, PagosComponent, ComprasCorporativasComponent, FacturasContComponent,
    OtrosComponent, OtrosIndex,
    RouteComponent, ReportesComponent,
    EmailComponent, PaqueteComponent,
    TarifasDeProductosComponent, TarifaProductoComponent,
    NotasDeCreditoComponent,
    AgregarCompraComponent, ComprasCorporativasIndex, FileDropComponent,
    CkeditorComponent, AddCurrencyRateComponent, AddRouteComponent, EditRouteComponent, AccionComponent, AccionesIndex,
    DescuentosIndex, DescuentoComponent,
    TarifaProductoIndex, NuevoComponent, VistaComponent,
    HistoryComponent, ChartsComponent, LineComponent, BarComponent, RadarComponent,
    DoughnutPieComponent, PolarAreaComponent, BubbleComponent, ScatterComponent,
    VistaComponent, HistoryComponent, EmbarqueComponent,
    NewPackageComponent, TarifaComponent, ProgramarEntregasComponent, IndexEntregasComponent, IndexEmbarquesComponent, EntregaComponent,
    EntregasComponent, EmbarqueComponent, EmbarquesComponent, MainEmbarquesComponent, MainEntregasComponent, PaqueteItemsComponent,
    CapturarEmbarquesComponent,
    CapturarEmbarquesAdminComponent,
    CameraComponent,
    CameraModalComponent,
    PackageLabelComponent,
    MainPaquetesComponent,
    PaquetesComponent,
    ResumenPaquetesComponent,
    BuscarComponent,
    ConsolidacionComponent,
    TarifasComponent,
    MainTarifasComponent,
    TarifaModalComponent,
    FacturaModalComponent,
    DialogModalComponent,
    ConsolidationModalComponent,
    CardComponent,
    EmailEditorModalComponent,
    WindowComponent,
    TransportistasComponent,
    TransportistaComponent,
    CapturarPaquetesZonaComponent,
    AprobarPagosComponent,
    TarjetasComponent,
    ReportViewerComponent,
    VendedorComponent,
    VendedoresComponent,
    MainSucursalesComponent,
    IndexSucursalesComponent,
    ClientesRutaComponent,
    FilterUsuarioEntregadoPipe,
    IntegracionesComponent,
    NotificacionesPushComponent,
    AlianzasComponent,
    IndexAlianzaComponent,
    AlianzaComponent,
    SuperboxComponent,
    FacturasSingenerarComponent,
    TipoClienteComponent,
    ContratoComponent,
    AppPackageComponent,
    GuiasComponent,
    GuiaComponent,
    UltimaActualizacionPipe,
    MassEmailsComponent,
    DeliveryTokenTableComponent
  ],
  imports: [
    AdminRoutingModule,
    GlobalModule,
    MaterialModule,
    NgxFileDropModule,
    CKEditorModule,
    ChartsModule,
    SharedComponentsModule,
    WebcamModule,
    ReactiveFormsModule,
    PortalModule,
    AgmCoreModule,
    ColorPickerModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
  ],
  exports: [HistoryComponent],
  providers: []
})
export class AdminModule { }
