<ng-container style="background-color: white;">
    <div style="margin-bottom: 50px; margin-top: 20px;" class="p-3 content" fxLayout="column">
      <div style="padding-top: 0;" class="content-title">
        <p class="title">Códigos de entregas</p>
      </div>
    </div>
  <div class="content-table m-4" id="users-table" style="display: flex; justify-content: center; background-color: white;">
    <table mat-table
      [dataSource]="dataSource.data "
      matSort style="width: auto;">

      <!-- account Column -->
      <ng-container matColumnDef="account">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Cuenta</th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          <a [routerLink]="['/admin/perfil']"
            [queryParams]="{cuenta: element.usuario.id}">{{element.usuario.cuenta}}--{{element.usuario.nombre}} {{element.usuario.primer_apellido}}</a>
        </td>
      </ng-container>

      <!-- Delivery Id Column -->
      <ng-container matColumnDef="deliveryId">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Entrega</th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.entrega.id}} </td>
      </ng-container>

       <!-- Driver Column -->
       <ng-container matColumnDef="driver">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Conductor</th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.entrega.conductor.nombre}} </td>
      </ng-container>

      <!-- token Column -->
      <ng-container matColumnDef="token">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.token}} </td>
      </ng-container>

      <!-- created_At Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de creación </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at | date:'dd-MM-yyyy HH:mm:ss' }} </td>
      </ng-container>

      <!-- Valid Column -->
      <ng-container matColumnDef="valid">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Validez </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.valid ? 'Activo' : 'Inactivo' }} </td>
      </ng-container>

       <!-- Max Date Column -->
      <ng-container matColumnDef="max_date">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha Expiración </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_date | date:'dd-MM-yyyy'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"
        [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }">
      </tr>
    </table>
  </div>
</ng-container>